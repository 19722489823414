<template>
  <div class="home">
    <img alt="logo" class="logo" src="../assets/logo.jpeg">

    <form action="" @submit.prevent="doSumbit">
      <label for="time">Select a time:</label>
      <VueCtkDateTimePicker v-model="time" format="YY-MM-DD HH:mm:ss" formatted="YYYY-MM-DD HH:mm:ss"/>

      </VueCtkDateTimePicker >
      <label for="location">Select location:</label>
      <div class="date-time-picker">
        <input type="text" v-model="location" name="location" id="location">
      </div>
      <label for="condition">Select condition:</label>
        <select name="condition" v-model="condition" id="condition">
          <option value="BLOCKED">BLOCKED</option>
          <option value="CLOSED">CLOSED</option>
          <option value="CONSTRUCTION">CONSTRUCTION</option>
          <option value="DETOUR">DETOUR</option>
          
        </select>
      <label for="extra">Extra Text:</label>
      <div class="date-time-picker">
        <textarea name="extra" v-model="extra" id="extra" cols="30" rows="10"></textarea>
      </div>
        
        <input type="submit" class="field-input" value="Submit">

    </form>
    <span class="preview">
      <h3>Tweet Preview</h3>
      <p>

      {{time}} | {{location}} | {{condition}}<br>{{extra}}
      <span>#BlockedInHamont</span>
      </p>
    </span>

  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
export default {
  name: 'Home',
  components: {
    VueCtkDateTimePicker
  },
  data() {
    return {
      time: '',
      extra: '',
      condition: '',
      location: ''
    }
  },
  methods: {
    doSumbit: function() {
      window.open("http://twitter.com/share?text="+this.formattedMsg+"&url=%20");
      //http://twitter.com/share?text=YOUR-TEXT&url=YOUR-URL
      // alert();
    }
  },
  computed: {
    formattedMsg: function() {
      return this.time + "%20%7C%20" + this.location + "%20%7C%20" + this. condition +"%0A%E2%81" + this.extra + "%0A%E2%81%A3%0A%23BlockedInHamont";
    }
  }
}
</script>
<style lang="scss">
  .logo {
    width: 50%;
  }
  input, textarea, select {
        cursor: pointer;
    background-color: #fff;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    position: relative;
    width: 100%;
    height: 42px;
    min-height: 42px;
    padding-left: 12px;
    padding-right: 44px;
    font-weight: 400;
    -webkit-appearance: none;
    outline: none;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 4px;
    font-size: 14px;
    z-index: 0;
    margin-bottom: 2em;
    
  }
  .preview, form {
    text-align: left;
    span {
      display: block;
    }
  }
</style>